import { Component, PathRouter, Router } from '@acryps/page';
import { PageComponent } from './page';
import { pageStyle } from "./index.style";
import { registerDirectives } from '@acryps/page-default-directives';
import { GameService, LocalizedTextViewModel, Service, SessionViewModel } from './managed/services';
import { GameComponent } from './game';
import { Language, findLanguage, languages } from '@acryps/language';

export class Application {	
	static readonly maxPlayers = 3;
	static readonly feedbackPageTimeout = 2000;

	static readonly sessionId = 'royal-walk-id';
	static readonly sessionLanguage = 'royal-walk-language';
	static readonly sessionEntryPoint = 'royal-walk-entry';
	static readonly sessionEntrySubmitted = 'royal-walk-entry-submitted';
	
	static session: SessionViewModel;
	static router: Router;

	static languages = [languages.english, languages.german, languages.french, languages.italian, languages.spanish, languages.arabic, languages.korean, languages.japanese];
	static physicallyLocalizedLanguages = [languages.english, languages.german];

	static async main() {
		Service.baseUrl = '/';

		if (!Language.active) {
			Application.setLanguage(Application.languages[0]);
		}

		if (localStorage.getItem(Application.sessionId)) {
			this.session = await new GameService().getSession(localStorage.getItem(Application.sessionId));
			this.sortPlayers();
		}

		if (localStorage.getItem(Application.sessionLanguage)) {
			Application.setLanguage(findLanguage(localStorage.getItem(Application.sessionLanguage)))
		}

		const router = this.router = new PathRouter(PageComponent
			.route('/:station', GameComponent)
			.route('', GameComponent)
		);
		
		registerDirectives(Component, this.router);

		pageStyle().apply();

		router.host(document.body);
	}

	static sortPlayers() {
		this.session.players.sort((a, b) => a.index - b.index);
	}

	static setLanguage(language: Language) {
		Language.active = language;

		// set language direction
		document.documentElement.dir = Language.active.direction;
	}
}

Application.main();

// make text view models automatically resolve to the current language
LocalizedTextViewModel.prototype.toString = function () {
	return this[Language.active.id];
};
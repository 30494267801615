import { Font, Variable, backgroundColor, backgroundImage, backgroundRepeat, backgroundSize, child, deg, display, fontSize, hex, hsl, justifyContent, margin, marginInline, min, padding, percentage, px, ratio, rem, rgb, root, url, vw, width } from "@acryps/style";
import { startStyle } from "./start/start.styles";
import { gameStyle } from "./game.styles";

export const contentSpacing = new Variable('content-spacing', rem(1.5));
export const pageSpacingInline = new Variable('page-spacing-inline', rem(1.5));
export const pageSpacingBlock = new Variable('page-spacing-block', rem(1.5));

export const playerSelectionCrownSpacing = new Variable('player-selection-crown-spacing', rem(1));

export const pageWidthMax = px(800);
export const playerSelectionWidthMax = px(550);
export const contentWidth = min(pageWidthMax, vw(100));
export const feedBackCrownWidth = new Variable('feedback-crown-width', percentage(100).subtract(pageSpacingInline.multiply(2)))

export const languageCrownCount = new Variable('language-crown-count', 3);
export const stationDotSize = new Variable('station-dot-size', rem(1));

export const pageBorderRadius = rem(0.75);
export const crownAspectRatio = ratio(1.7868852459, 1);
export const playerCrownAspectRatio = ratio(1.81492148019, 1);

export const quizIndex = 2;
export const feedbackIndex = 3;

export const fontSizeMultiplicator = new Variable('font-size-multiplicator', 1);

export const textFontSize = rem(1.15).multiply(fontSizeMultiplicator);
export const playerFontSize = rem(1).multiply(fontSizeMultiplicator);
export const subTitleFontSize = rem(1.5).multiply(fontSizeMultiplicator);
export const titleFontSize = rem(2.25).multiply(fontSizeMultiplicator);
export const languageSelectionSubTitleFontSize = rem(1.75).multiply(fontSizeMultiplicator);

export const startCrownContentFontSize = rem(1.25).multiply(fontSizeMultiplicator);;
export const figureSearchFontSize = rem(2).multiply(fontSizeMultiplicator);

export const gold = hsl(deg(43), percentage(65), percentage(60));
export const green = hex('#228B22');
export const red =  hex('#D6001B');

export const primaryColor = red;
export const primaryContrastColor = hex('#FFF');
export const secondaryColor = gold;
export const secondaryContrastColor = hex('#000');
export const panelBackgroundColor = rgb(0xff, 0xff, 0xff, 0.8);
export const borderColor = hex('#BEBEBE');
export const errorColor = hex('#FF3333');
export const successColor = green;
export const transparentColor = hex('#00000000');

export const stationPointColor = red;
export const stationPointFinishedColor = green;
export const stationPointDisabledColor = hex('#808080');

export const pageBackgroundColor = new Variable('page-background', gold);
export const ornamentColor = hex('#d3ab3b');

export const contentFont = new Font('Myriad Pro Regular').addSource('/assets/fonts/myriadPro/regular.woff2');
export const subHeaderFont = new Font('Myriad Pro Bold').addSource('/assets/fonts/myriadPro/semibold.woff2');
export const monospaceFont = new Font('Share Tech Mono').addSource('/assets/fonts/shareTechMono.woff2');
export const headerFont = new Font('Playball').addSource('/assets/fonts/playball.woff2');

export const pageStyle = () => root(
	headerFont,
	contentFont,
	subHeaderFont,
	monospaceFont,

	contentSpacing,
	pageSpacingInline,
	pageSpacingBlock,

	playerSelectionCrownSpacing,

	feedBackCrownWidth,
	languageCrownCount,
	stationDotSize,

	fontSizeMultiplicator,
	pageBackgroundColor,

	child('body', 
		padding(0),
		margin(0),

		backgroundImage(url('/assets/images/ornament.svg')),
		backgroundRepeat('repeat'),
		backgroundSize([rem(6.75)]),
		backgroundColor(ornamentColor),

		contentFont,
		fontSize(textFontSize),

		child('ui-page',
			display('flex'),
			
			width(contentWidth),
			marginInline('auto'),
			justifyContent('center'),

			startStyle(),
			gameStyle(),
		)
	)
);
import { Component } from "@acryps/page";
import { Application } from "..";
import { Language } from '@acryps/language';
import { GameComponent } from "../game";

export class StartLanguageComponent extends Component {
	declare parent: GameComponent;

	render() {
		return <ui-language-selection>
			<ui-header>
				<ui-image><img src={'/assets/images/start.svg'} /></ui-image>
				<ui-title>
					{'Royal Walk Treasure Hunt Game'
						.german('Royal Walk Schatzsuche Spiel')
						.french('Jeu de chasse au trésor Royal Walk')
						.italian('Gioco della caccia al tesoro di Royal Walk')
						.spanish('Juego de búsqueda del tesoro Royal Walk')
						.arabic('لعبة البحث عن الكنز في الممشى الملكي')
						.korean('로얄 워크 보물찾기 게임')
						.japanese('ロイヤルウォーク宝探しゲーム')
					}
				</ui-title>

				<ui-subtitle>
					{'Welcome to the game'
						.german('Willkommen zum Spiel')
						.french('Bienvenue dans le jeu')
						.italian('Benvenuti nel gioco')
						.spanish('Bienvenido al juego')
						.arabic('مرحباً بك في اللعبة')
						.korean('게임에 오신 것을 환영합니다.')
						.japanese('試合へようこそ')
					}
				</ui-subtitle>
			</ui-header>

			<ui-languages>
				{Application.languages.map(language => <ui-flag-container
					ui-active={language == Language.active}
					ui-click={() => {
						Application.setLanguage(language);

						this.update();
					}}
				>
					<ui-flag></ui-flag>
					<ui-content>
						{language.name}
					</ui-content>
				</ui-flag-container>)}
			</ui-languages>

			<ui-button ui-click={() => {
				if (Language.active) {
					this.parent.setLanguage();
				}
			}}>
				{'Select'
					.german('Wählen')
					.french('Sélectionner')
					.italian('Seleziona')
					.spanish('Seleccione')
					.arabic('اختر')
					.korean('선택')
					.japanese('選択')
				}
			</ui-button>
		</ui-language-selection>;
	}
}
import { Component } from "@acryps/page";
import { StationViewModel } from "../managed/services";
import { stationX, stationY } from "./quiz.style";
import { QuizComponent } from "./quiz";
import { Application } from "..";
import { Language } from "@acryps/language";

export class MapOverviewComponent extends Component {
	declare parent: QuizComponent;

	constructor (
		private stations: StationViewModel[],
		private currentStation?: string | StationViewModel
	) {
		super();
	}

	render() {
		return <ui-map-overview>
			{this.currentStation instanceof StationViewModel && !Application.physicallyLocalizedLanguages.includes(Language.active) && <ui-station-title>{this.currentStation.title}</ui-station-title>}

			{this.currentStation instanceof StationViewModel && !Application.physicallyLocalizedLanguages.includes(Language.active) && this.currentStation.stationParagraphs && this.currentStation.stationParagraphs.sort((a, b) => a.orderIndex - b.orderIndex).map(stationParagraph => <ui-station-content>
				{stationParagraph.title && <ui-title>
					{stationParagraph.title}
				</ui-title>}

				{stationParagraph.subTitle && <ui-subTitle>
					{stationParagraph.subTitle}	
				</ui-subTitle>}

				<ui-paragraph>
					{stationParagraph.paragraph}
				</ui-paragraph>
			</ui-station-content>)}

			{this.currentStation && <ui-title>
				{'Map overview'
					.german('Übersichtskarte')
					.french('Aperçu de la carte')
					.italian('Panoramica della mappa')
					.spanish('Mapa general')
					.arabic('نظرة عامة على الخريطة')
					.korean('지도 개요')
					.japanese('地図概要')
				}
			</ui-title>}

			{this.currentStation && <ui-text>
				{'The tasks at the green stations have been completed.'
					.german('Bei den grünen Stationen sind die Aufgaben gelöst.')
					.french('Les tâches des stations vertes sont terminées.')
					.italian('I compiti delle stazioni verdi sono stati completati.')
					.spanish('Se han completado las tareas en las estaciones verdes.')
					.arabic('تم الانتهاء من المهام في المحطات الخضراء.')
					.korean('녹색 스테이션의 작업이 완료되었습니다.')
					.japanese('グリーンステーションのタスクは完了した。')
				}
			</ui-text>}

			<ui-map>
				{this.stations.map(station => <ui-station-point
					ui-disabled={!station.gameTitle}
					ui-finished={this.currentStation ? this.parent.stationFinished(station) : false}
					style={`${stationX.provide(station.x)};${stationY.provide(station.y)}`}
				></ui-station-point>)}

				<img src={'/assets/images/map.svg'} />
			</ui-map>

			{this.currentStation && (Application.session.players.length == 1 ? <ui-information>
				{'Go to the next station. A new task awaits you there.'
					.german('Gehe zur nächsten Station. Dort erwartet dich eine neue Aufgabe.')
					.french('Rendez-vous à la station suivante. Une nouvelle tâche vous y attend.')
					.italian('Andate alla stazione successiva. Lì vi aspetta un nuovo compito.')
					.spanish('Ve a la siguiente estación. Allí te espera una nueva tarea.')
					.arabic('انتقل إلى المحطة التالية. تنتظرك مهمة جديدة هناك.')
					.korean('다음 역으로 이동합니다. 새로운 임무가 기다리고 있습니다.')
					.japanese('次の駅に行く。そこで新しい仕事が待っている。')
				}
			</ui-information> : <ui-information>
				{'Go to the next station. New tasks await you there.'
					.german('Geht zur nächsten Station. Dort erwarten euch neue Aufgaben.')
					.french('Rendez-vous à la station suivante. De nouvelles tâches vous y attendent.')
					.italian('Andate alla stazione successiva. Lì vi aspettano nuovi compiti.')
					.spanish('Ve a la siguiente estación. Allí te esperan nuevas tareas.')
					.arabic('انتقل إلى المحطة التالية. تنتظرك مهام جديدة هناك.')
					.korean('다음 역으로 이동합니다. 새로운 작업이 기다리고 있습니다.')
					.japanese('次の駅に行く。そこで新しい仕事が待っている。')
				}
			</ui-information>)}
		</ui-map-overview>;
	}
}
import { Component } from "@acryps/page";
import { deg, hsl, percentage } from "@acryps/style";
import { pageBackgroundColor } from "../index.style";
import { Application } from "..";

export class FeedbackComponent extends Component {
	open = false;
	source: string;
	content: string;

	constructor () {
		super();
	}

	render() {
		if (!this.open) {
			return <ui-void></ui-void>;
		}

		return <ui-feedback>
			<ui-content>
				{this.content ? <ui-text>
					{this.content}
				</ui-text> : <img src={this.source} />}
			</ui-content>
		</ui-feedback>;
	}

	show(type: boolean, content?: string) {
		if (content) {
			this.content = content;
		} else {
			if (!type) {
				this.source = '/assets/images/wrong.svg';
			} else {
				this.source = '/assets/images/correct.svg';
			}
		}

		// update page background color
		pageBackgroundColor.update(hsl(deg(46), percentage(62), percentage(91)));
		this.open = true;

		this.update();

		setTimeout(() => {
			this.open = false;

			this.parent.update();
		}, Application.feedbackPageTimeout);
	}
}
import { Component } from "@acryps/page";
import { GameComponent } from "../game";
import { MapOverviewComponent } from "../quiz/map-overview";
import { QuizService, StationViewModel } from "../managed/services";
					
export class GameIntroductionComponent extends Component {
	declare parent: GameComponent;

	stations: StationViewModel[]; 

	async onload() {
		this.stations = await new QuizService().getStations();
	}

	render() {
		return <ui-game-introduction>
			<ui-title>
				{'Discover the treasure!'
					.german('Entdecke den Schatz!')
					.french('Découvre le trésor !')
					.italian('Scoprite il tesoro!')
					.spanish('¡Descubre el tesoro!')
					.arabic('اكتشف الكنز!')
					.korean('보물을 발견하세요!')
					.japanese('宝物を見つけよう！')
				}
			</ui-title>

			{new MapOverviewComponent(this.stations)}
			
			<ui-description>
				{"On the Royal Walk route, a game and a task await you at each of the six stations marked in red. Scan the QR code and solve the task. Once you have solved all six, a secret code will lead you to the treasure.\n\nUp to three players can take part at the same time using a mobile device. It doesn't matter in which order you solve the tasks."
					.german('Auf dem Royal Walk Weg erwarten dich bei jeder der sechs rot markierten Stationen ein Spiel und eine Aufgabe. Scanne den QR-Code ab und löse die Aufgabe. Wenn du alle sechs gelöst hast, führt dich ein Geheimcode zum Schatz.\n\nMit einem Mobilgerät können bis zu drei Spieler gleichzeitig teilnehmen. Es spielt keine Rolle, in welcher Reihenfolge du die Aufgaben löst.')
					.french("Sur le parcours de la promenade royale, un jeu et une tâche vous attendent à chacune des six stations marquées en rouge. Scannez le code QR et résolvez la tâche. Une fois les six tâches résolues, un code secret vous mènera au trésor.\n\nAvec un appareil mobile, jusqu'à trois joueurs peuvent participer simultanément. L'ordre dans lequel tu résous les tâches n'a pas d'importance.")
					.italian("Lungo il percorso della Royal Walk, in ognuna delle sei stazioni contrassegnate in rosso vi attendono un gioco e un compito. Scansionate il codice QR e risolvete il compito. Una volta risolti tutti e sei, un codice segreto vi condurrà al tesoro.\n\nPossono partecipare fino a tre giocatori contemporaneamente utilizzando un dispositivo mobile. Non importa l'ordine in cui si risolvono i compiti.")
					.spanish('En la ruta del Paseo Real te esperan un juego y una tarea en cada una de las seis estaciones marcadas en rojo. Escanea el código QR y resuelve la tarea. Cuando hayas resuelto las seis, un código secreto te conducirá al tesoro.\n\nPueden participar hasta tres jugadores al mismo tiempo utilizando un dispositivo móvil. No importa en qué orden resuelvas las tareas.')
					.arabic('على طريق الممشى الملكي، تنتظرك لعبة ومهمة في كل محطة من المحطات الست المميزة باللون الأحمر. امسح رمز الاستجابة السريعة وحل المهمة. بمجرد أن تحل جميع المهام الست، سيقودك الرمز السري إلى الكنز.\n\nيمكن لثلاثة لاعبين على الأكثر المشاركة في نفس الوقت باستخدام جهاز محمول. لا يهم الترتيب الذي تحل به المهام.')
					.korean('로열 워크 루트에서는 빨간색으로 표시된 6개의 역마다 게임과 미션이 기다리고 있습니다. QR 코드를 스캔하고 과제를 해결하세요. 여섯 가지를 모두 풀면 비밀 코드가 보물로 안내합니다.\n\n모바일 기기를 사용하여 최대 3명의 플레이어가 동시에 참여할 수 있습니다. 과제를 해결하는 순서는 중요하지 않습니다.')
					.japanese('ロイヤルウォークのルートでは、赤い印の6つの駅でゲームと課題があなたを待っています。QRコードをスキャンして課題を解いてください。6つすべてを解くと、秘密のコードが宝物へと導いてくれる。\n\nモバイル機器を使って最大3人まで同時に参加できます。タスクを解く順番は関係ありません。')
				}
			</ui-description>

			<ui-button ui-click={() => {
				this.parent.setEntry();
			}}>
				{'Next'
					.german('Weiter')	
					.french('Suivant')
					.italian('Avanti')
					.spanish('Siguiente')
					.arabic('التالي')
					.korean('다음')
					.japanese('次のページ')
				}
			</ui-button>
		</ui-game-introduction>;
	}
}
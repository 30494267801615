import { alignItems, aspectRatio, backgroundImage, backgroundRepeat, backgroundSize, child, color, display, flexDirection, flexGrow, flexWrap, fontSize, height, justifyContent, marginBlock, marginBottom, marginInline, marginTop, min, paddingBlock, paddingInline, percentage, pointerEvents, position, ratio, rem, style, textAlign, url, whiteSpace, width } from "@acryps/style";
import { contentSpacing, languageCrownCount, languageSelectionSubTitleFontSize, pageSpacingBlock, pageSpacingInline, playerSelectionCrownSpacing, playerSelectionWidthMax, primaryContrastColor, secondaryContrastColor, startCrownContentFontSize, subHeaderFont } from "../index.style";
import { buttonStyle, crownStyle, defaultScreenStyle, inputStyle, mapOverviewStyle, panelBase, titleStyle } from "../shared/shared.style";

export const languageSpacing = rem(0.5);

export const startStyle = () => child('ui-start',
	display('flex'),
	paddingInline(pageSpacingInline),
	paddingBlock(pageSpacingBlock),
	
	defaultScreenStyle(),

	child('ui-game-introduction',
		panelBase(),

		display('flex'),
		flexDirection('column'),

		width(percentage(100)),

		child('ui-map-overview',
			mapOverviewStyle(),
			
			display('flex'),
			marginTop(contentSpacing)
		),

		child('ui-title',
			textAlign('center'),

			titleStyle(),
		),

		child('ui-description',
			marginBlock(contentSpacing)
		),

		child('ui-button',
			buttonStyle(),
		)
	),

	child('ui-language-selection',
		display('flex'),
		flexDirection('column'),

		width(percentage(100)),

		child('ui-header',
			panelBase(),

			child('ui-image',
				display('flex'),
				paddingInline(rem(2)),
				paddingBlock(rem(1)),

				fontSize(0),

				child('img',
					width(percentage(100))
				)
			),

			child('ui-title',
				marginTop(contentSpacing),
				textAlign('center'),

				titleStyle()
			),

			child('ui-subtitle',
				marginTop(contentSpacing),
				textAlign('center'),

				subHeaderFont,
				fontSize(languageSelectionSubTitleFontSize),
			)
		),

		child('ui-languages',
			display('flex'),
			flexWrap('wrap'),

			marginInline(languageSpacing.multiply(-1)),
			marginBlock(contentSpacing.divide(2)),

			child('ui-flag-container',
				position('relative'),

				display('flex'),
				flexDirection('column'),
				alignItems('center'),

				width(percentage(100).divide(languageCrownCount).subtract(languageSpacing.multiply(2))),
				marginBlock(contentSpacing.divide(2)),
				marginInline(languageSpacing),
				
				aspectRatio(ratio(1.48413220842, 1)),

				style('[ui-active]',
					pointerEvents('none'),
						
					child('ui-flag',
						backgroundImage(url('/assets/images/flag-active.svg')),
					),

					child('ui-content',
						color(primaryContrastColor),
					),
				),

				child('ui-flag',
					width(percentage(100)),
					
					aspectRatio(ratio(1.48413220842, 1)),
					backgroundImage(url('/assets/images/flag.svg')),
					backgroundRepeat('no-repeat'),
					backgroundSize([percentage(100)])
				),

				child('ui-content',
					position('absolute'),

					display('flex'),
					flexDirection('column'),
					justifyContent('center'),
					height(percentage(100)),
					
					fontSize(startCrownContentFontSize),
					color(secondaryContrastColor),
				)
			)
		),

		child('ui-button',
			buttonStyle(),
			marginTop('auto'),
		),
	),

	child('ui-player-selection',
		panelBase(),

		display('flex'),
		flexDirection('column'),

		width(percentage(100)),

		child('ui-title',
			textAlign('center'),

			titleStyle()
		),

		child('ui-instruction',
			marginBlock(contentSpacing),
			whiteSpace('pre-wrap'),
		),

		child('ui-players',
			display('flex'),
			flexDirection('column'),

			width(min(percentage(100), playerSelectionWidthMax)),
			marginInline('auto'),

			child('ui-crown-container',
				crownStyle(),

				style(':not(:last-child)',
					marginBottom(rem(2))
				),

				child('ui-content',
					height(percentage(57)),
					width(percentage(65)),
					
					fontSize(startCrownContentFontSize),
					color(primaryContrastColor),

					child('ui-title',
						display('flex'),
						flexGrow(1),
						alignItems('center'),
						justifyContent('center'),
						
						subHeaderFont,
					),

					child('input',
						inputStyle(),

						width(percentage(100).subtract(rem(1).multiply(2))),
						marginTop('auto'),
						marginBottom(playerSelectionCrownSpacing),
					)
				)
			),
		),

		
		child('ui-button',
			buttonStyle(),
			marginTop(rem(3)),
		),
	)
);
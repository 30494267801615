import { Component } from "@acryps/page";
import { Application } from ".";
import { StartLanguageComponent } from "./start/language";
import { StartPlayerComponent } from "./start/player";
import { GameService } from "./managed/services";
import { Language } from "@acryps/language";
import { QuizComponent } from "./quiz/quiz";
import { GameIntroductionComponent } from "./start/game-introduction";
import { DefaultScreenComponent } from "./shared/default-screen";

export class GameComponent extends Component {
	declare parameters: { station: string };

	defaultScreen: DefaultScreenComponent;

	onload() {
		if (!this.parameters.station) {
			this.defaultScreen = new DefaultScreenComponent(
				'Invalid station'
					.german('Ungültige Station')
					.french('Station invalide')
					.italian('Stazione non valida')
					.spanish('Estación no válida')
					.arabic('محطة غير صالحة')
					.korean('잘못된 스테이션')
					.japanese('無効なステーション'),
				[
					'Scan the QR code on the sign on the stele.'
						.german('Scanne den QR-Code auf dem Schild der Stele.')
						.french('Scannez le code QR sur le panneau de la stèle.')
						.italian('Scansionate il codice QR sul cartello della stele.')
						.spanish('Escanea el código QR que aparece en el cartel de la estela.')
						.arabic('امسح رمز الاستجابة السريعة الموجود على اللافتة الموجودة على الشاهدة.')
						.korean('비석에 있는 표지판에 있는 QR 코드를 스캔하세요.')
						.japanese('石碑の看板にあるQRコードをスキャンする。'),
				]
			);

			this.defaultScreen.show();
		}
	}

	render() {
		if (this.defaultScreen) {
			return <ui-start>
				{this.defaultScreen}
			</ui-start>;
		}

		if (!localStorage.getItem(Application.sessionLanguage)) {
			return <ui-start>
				{new StartLanguageComponent()}
			</ui-start>;
		}

		if (!localStorage.getItem(Application.sessionEntryPoint)) {
			return <ui-start>
				{new GameIntroductionComponent()}
			</ui-start>;
		}

		if (!Application.session) {
			return <ui-start>
				{new StartPlayerComponent()}
			</ui-start>;
		}

		return <ui-game>
			{new QuizComponent(this.parameters.station)}
		</ui-game>;
	}

	async setEntry() {
		localStorage.setItem(Application.sessionEntryPoint, 'started');
		await new GameService().setEntryPoint(this.parameters.station);

		this.update(); 
	}

	async setLanguage() {
		localStorage.setItem(Application.sessionLanguage, Language.active.id);

		this.update(); 
	}

	async createSession(names) {
		const session = await new GameService().createGame(Language.active.id, names);

		Application.session = session;
		Application.sortPlayers();

		localStorage.setItem(Application.sessionId, session.id);

		this.update();
	}
}
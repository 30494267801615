export class SessionViewModel {
	contestEntries: ContestEntryViewModel[];
	players: PlayerViewModel[];
	createdAt: Date;
	id: string;
	language: string;

	private static $build(raw) {
		const item = new SessionViewModel();
		raw.contestEntries === undefined || (item.contestEntries = raw.contestEntries ? raw.contestEntries.map(i => ContestEntryViewModel["$build"](i)) : null)
		raw.players === undefined || (item.players = raw.players ? raw.players.map(i => PlayerViewModel["$build"](i)) : null)
		raw.createdAt === undefined || (item.createdAt = raw.createdAt ? new Date(raw.createdAt) : null)
		raw.id === undefined || (item.id = raw.id === null ? null : `${raw.id}`)
		raw.language === undefined || (item.language = raw.language === null ? null : `${raw.language}`)
		
		return item;
	}
}

export class PlayerViewModel {
	figure: FigureViewModel;
	visits: VisitViewModel[];
	id: string;
	index: number;
	name: string;
	submittedAt: Date;
	unlockedAt: Date;

	private static $build(raw) {
		const item = new PlayerViewModel();
		raw.figure === undefined || (item.figure = raw.figure ? FigureViewModel["$build"](raw.figure) : null)
		raw.visits === undefined || (item.visits = raw.visits ? raw.visits.map(i => VisitViewModel["$build"](i)) : null)
		raw.id === undefined || (item.id = raw.id === null ? null : `${raw.id}`)
		raw.index === undefined || (item.index = raw.index === null ? null : +raw.index)
		raw.name === undefined || (item.name = raw.name === null ? null : `${raw.name}`)
		raw.submittedAt === undefined || (item.submittedAt = raw.submittedAt ? new Date(raw.submittedAt) : null)
		raw.unlockedAt === undefined || (item.unlockedAt = raw.unlockedAt ? new Date(raw.unlockedAt) : null)
		
		return item;
	}
}

export class EntryPointViewModel {
	count: number;
	id: string;
	tag: string;

	private static $build(raw) {
		const item = new EntryPointViewModel();
		raw.count === undefined || (item.count = raw.count === null ? null : +raw.count)
		raw.id === undefined || (item.id = raw.id === null ? null : `${raw.id}`)
		raw.tag === undefined || (item.tag = raw.tag === null ? null : `${raw.tag}`)
		
		return item;
	}
}

export class StationViewModel {
	gameDescription: LocalizedTextViewModel;
	gameTitle: LocalizedTextViewModel;
	questions: QuestionViewModel[];
	stationParagraphs: StationParagraphsViewModel[];
	title: LocalizedTextViewModel;
	id: string;
	tag: string;
	x: number;
	y: number;

	private static $build(raw) {
		const item = new StationViewModel();
		raw.gameDescription === undefined || (item.gameDescription = raw.gameDescription ? LocalizedTextViewModel["$build"](raw.gameDescription) : null)
		raw.gameTitle === undefined || (item.gameTitle = raw.gameTitle ? LocalizedTextViewModel["$build"](raw.gameTitle) : null)
		raw.questions === undefined || (item.questions = raw.questions ? raw.questions.map(i => QuestionViewModel["$build"](i)) : null)
		raw.stationParagraphs === undefined || (item.stationParagraphs = raw.stationParagraphs ? raw.stationParagraphs.map(i => StationParagraphsViewModel["$build"](i)) : null)
		raw.title === undefined || (item.title = raw.title ? LocalizedTextViewModel["$build"](raw.title) : null)
		raw.id === undefined || (item.id = raw.id === null ? null : `${raw.id}`)
		raw.tag === undefined || (item.tag = raw.tag === null ? null : `${raw.tag}`)
		raw.x === undefined || (item.x = raw.x === null ? null : +raw.x)
		raw.y === undefined || (item.y = raw.y === null ? null : +raw.y)
		
		return item;
	}
}

export class VisitViewModel {
	id: string;
	stationId: string;
	visitedAt: Date;

	private static $build(raw) {
		const item = new VisitViewModel();
		raw.id === undefined || (item.id = raw.id === null ? null : `${raw.id}`)
		raw.stationId === undefined || (item.stationId = raw.stationId === null ? null : `${raw.stationId}`)
		raw.visitedAt === undefined || (item.visitedAt = raw.visitedAt ? new Date(raw.visitedAt) : null)
		
		return item;
	}
}

export class FigureViewModel {
	image: ImageViewModel;
	text: LocalizedTextViewModel;
	title: LocalizedTextViewModel;
	code: string;
	icon: string;
	id: string;

	private static $build(raw) {
		const item = new FigureViewModel();
		raw.image === undefined || (item.image = raw.image ? ImageViewModel["$build"](raw.image) : null)
		raw.text === undefined || (item.text = raw.text ? LocalizedTextViewModel["$build"](raw.text) : null)
		raw.title === undefined || (item.title = raw.title ? LocalizedTextViewModel["$build"](raw.title) : null)
		raw.code === undefined || (item.code = raw.code === null ? null : `${raw.code}`)
		raw.icon === undefined || (item.icon = raw.icon === null ? null : `${raw.icon}`)
		raw.id === undefined || (item.id = raw.id === null ? null : `${raw.id}`)
		
		return item;
	}
}

export class QuestionViewModel {
	name: LocalizedTextViewModel;
	answers: AnswerViewModel[];
	figureId: string;
	id: string;

	private static $build(raw) {
		const item = new QuestionViewModel();
		raw.name === undefined || (item.name = raw.name ? LocalizedTextViewModel["$build"](raw.name) : null)
		raw.answers === undefined || (item.answers = raw.answers ? raw.answers.map(i => AnswerViewModel["$build"](i)) : null)
		raw.figureId === undefined || (item.figureId = raw.figureId === null ? null : `${raw.figureId}`)
		raw.id === undefined || (item.id = raw.id === null ? null : `${raw.id}`)
		
		return item;
	}
}

export class AnswerViewModel {
	name: LocalizedTextViewModel;
	correct: boolean;
	id: string;

	private static $build(raw) {
		const item = new AnswerViewModel();
		raw.name === undefined || (item.name = raw.name ? LocalizedTextViewModel["$build"](raw.name) : null)
		raw.correct === undefined || (item.correct = !!raw.correct)
		raw.id === undefined || (item.id = raw.id === null ? null : `${raw.id}`)
		
		return item;
	}
}

export class ContestEntryViewModel {
	emailAddress: string;
	firstName: string;
	id: string;
	lastName: string;
	submittedAt: Date;

	private static $build(raw) {
		const item = new ContestEntryViewModel();
		raw.emailAddress === undefined || (item.emailAddress = raw.emailAddress === null ? null : `${raw.emailAddress}`)
		raw.firstName === undefined || (item.firstName = raw.firstName === null ? null : `${raw.firstName}`)
		raw.id === undefined || (item.id = raw.id === null ? null : `${raw.id}`)
		raw.lastName === undefined || (item.lastName = raw.lastName === null ? null : `${raw.lastName}`)
		raw.submittedAt === undefined || (item.submittedAt = raw.submittedAt ? new Date(raw.submittedAt) : null)
		
		return item;
	}
}

export class StationParagraphsViewModel {
	paragraph: LocalizedTextViewModel;
	subTitle: LocalizedTextViewModel;
	title: LocalizedTextViewModel;
	id: string;
	orderIndex: number;

	private static $build(raw) {
		const item = new StationParagraphsViewModel();
		raw.paragraph === undefined || (item.paragraph = raw.paragraph ? LocalizedTextViewModel["$build"](raw.paragraph) : null)
		raw.subTitle === undefined || (item.subTitle = raw.subTitle ? LocalizedTextViewModel["$build"](raw.subTitle) : null)
		raw.title === undefined || (item.title = raw.title ? LocalizedTextViewModel["$build"](raw.title) : null)
		raw.id === undefined || (item.id = raw.id === null ? null : `${raw.id}`)
		raw.orderIndex === undefined || (item.orderIndex = raw.orderIndex === null ? null : +raw.orderIndex)
		
		return item;
	}
}

export class LocalizedTextViewModel {
	ar: string;
	de: string;
	en: string;
	es: string;
	fr: string;
	id: string;
	it: string;
	ja: string;
	ko: string;

	private static $build(raw) {
		const item = new LocalizedTextViewModel();
		raw.ar === undefined || (item.ar = raw.ar === null ? null : `${raw.ar}`)
		raw.de === undefined || (item.de = raw.de === null ? null : `${raw.de}`)
		raw.en === undefined || (item.en = raw.en === null ? null : `${raw.en}`)
		raw.es === undefined || (item.es = raw.es === null ? null : `${raw.es}`)
		raw.fr === undefined || (item.fr = raw.fr === null ? null : `${raw.fr}`)
		raw.id === undefined || (item.id = raw.id === null ? null : `${raw.id}`)
		raw.it === undefined || (item.it = raw.it === null ? null : `${raw.it}`)
		raw.ja === undefined || (item.ja = raw.ja === null ? null : `${raw.ja}`)
		raw.ko === undefined || (item.ko = raw.ko === null ? null : `${raw.ko}`)
		
		return item;
	}
}

export class ImageViewModel {
	id: string;

	private static $build(raw) {
		const item = new ImageViewModel();
		raw.id === undefined || (item.id = raw.id === null ? null : `${raw.id}`)
		
		return item;
	}
}

export class Service {
	static baseUrl = "";

	static toURL(request) {
		return `${this.baseUrl}${request}`;
	}
}

export class GameService {
	async createGame(language: string, players: string[]): Promise<SessionViewModel> {
		const $data = new FormData();
		$data.append("J2eXtydzZ1dWZlZmlqanNrbmN0dGE3NH", JSON.stringify(language))
		$data.append("BxMHdkdjc5MzhmNmx5NWY1aztqdGZwcW", JSON.stringify(players))

		return await fetch(Service.toURL("RjczQ2ZnF2b3dieDpvZzlmbDQ1MWVyd2"), {
			method: "post",
			credentials: "include",
			body: $data
		}).then(res => res.json()).then(r => {
			if ("data" in r) {
				const d = r.data;

				return d === null ? null : SessionViewModel["$build"](d);
			} else if ("aborted" in r) {
				throw new Error("request aborted by server");
			} else if ("error" in r) {
				throw new Error(r.error);
			}
		});
	}

	async getEntryPoint(tag: string): Promise<EntryPoint> {
		const $data = new FormData();
		$data.append("pkMWY1OHE0dDI2N3Q4NGk1aDUyZGg0OW", JSON.stringify(tag))

		return await fetch(Service.toURL("5iYTkxMnlqcWJoZWE3MD9qNmk1dzk5MG"), {
			method: "post",
			credentials: "include",
			body: $data
		}).then(res => res.json()).then(r => {
			if ("data" in r) {
				const d = r.data;

				return d === null ? null : EntryPoint["$build"](d);
			} else if ("aborted" in r) {
				throw new Error("request aborted by server");
			} else if ("error" in r) {
				throw new Error(r.error);
			}
		});
	}

	async setEntryPoint(tag: string): Promise<void> {
		const $data = new FormData();
		$data.append("RmZjk5M3g5OW01cDdwaWBoZXFocTlsYX", JSON.stringify(tag))

		return await fetch(Service.toURL("V0MXE3cmBnYWlydzp3ODU0eHowdjR1NW"), {
			method: "post",
			credentials: "include",
			body: $data
		}).then(res => res.json()).then(r => {
			if ("error" in r) {
				throw new Error(r.error);
			}

			if ("aborted" in r) {
				throw new Error("request aborted by server");
			}
		});
	}

	async getSession(sessionId: string): Promise<SessionViewModel> {
		const $data = new FormData();
		$data.append("ZycHtnY3E4M3lub2JuNnlxdXdocTtjOG", JSON.stringify(sessionId))

		return await fetch(Service.toURL("N1Y2dhY3htcTZoYTc2cmo0NHNhZ2hsOW"), {
			method: "post",
			credentials: "include",
			body: $data
		}).then(res => res.json()).then(r => {
			if ("data" in r) {
				const d = r.data;

				return d === null ? null : SessionViewModel["$build"](d);
			} else if ("aborted" in r) {
				throw new Error("request aborted by server");
			} else if ("error" in r) {
				throw new Error(r.error);
			}
		});
	}

	async exportCode(): Promise<string> {
		const $data = new FormData();
		

		return await fetch(Service.toURL("t5NWlrdHxpZW92a3FmMzw3dndodjlkam"), {
			method: "post",
			credentials: "include",
			body: $data
		}).then(res => res.json()).then(r => {
			if ("data" in r) {
				const d = r.data;

				return d === null ? null : `${d}`;
			} else if ("aborted" in r) {
				throw new Error("request aborted by server");
			} else if ("error" in r) {
				throw new Error(r.error);
			}
		});
	}
}

export class QuizService {
	async getStation(tag: string): Promise<StationViewModel> {
		const $data = new FormData();
		$data.append("hjajY5cTBscDhodXk1bmN5aWl1djhnbW", JSON.stringify(tag))

		return await fetch(Service.toURL("JtZ2k0dn1zbnN0Y3VhcDFoZmJoNWwzYz"), {
			method: "post",
			credentials: "include",
			body: $data
		}).then(res => res.json()).then(r => {
			if ("data" in r) {
				const d = r.data;

				return d === null ? null : StationViewModel["$build"](d);
			} else if ("aborted" in r) {
				throw new Error("request aborted by server");
			} else if ("error" in r) {
				throw new Error(r.error);
			}
		});
	}

	async getStations(): Promise<Array<StationViewModel>> {
		const $data = new FormData();
		

		return await fetch(Service.toURL("szbWZ0NXk1dndidjo4eWJjYzkwM2Q2eX"), {
			method: "post",
			credentials: "include",
			body: $data
		}).then(res => res.json()).then(r => {
			if ("data" in r) {
				const d = r.data;

				return d.map(d => d === null ? null : StationViewModel["$build"](d));
			} else if ("aborted" in r) {
				throw new Error("request aborted by server");
			} else if ("error" in r) {
				throw new Error(r.error);
			}
		});
	}

	async visitStation(playerId: string, stationId: string): Promise<VisitViewModel> {
		const $data = new FormData();
		$data.append("pwaTBoa35xajMxd3JqeXVrdHdkcH9nM3", JSON.stringify(playerId))
		$data.append("o1d35rdG9yaGR2dWFhdWFlYXg4c2lpZD", JSON.stringify(stationId))

		return await fetch(Service.toURL("VzcDB1c2UzcT9ybH50bTo1Mmd5ZWk3eW"), {
			method: "post",
			credentials: "include",
			body: $data
		}).then(res => res.json()).then(r => {
			if ("data" in r) {
				const d = r.data;

				return d === null ? null : VisitViewModel["$build"](d);
			} else if ("aborted" in r) {
				throw new Error("request aborted by server");
			} else if ("error" in r) {
				throw new Error(r.error);
			}
		});
	}

	async codeUnlocked(unlockedPlayer: PlayerViewModel): Promise<Date> {
		const $data = new FormData();
		$data.append("gzemMyeHp5MDJ4YWlzeXZuaWdkczhpaH", JSON.stringify(unlockedPlayer))

		return await fetch(Service.toURL("ZwNmNmNT80Y3h3OWRpaDUxMXk5bWNlZz"), {
			method: "post",
			credentials: "include",
			body: $data
		}).then(res => res.json()).then(r => {
			if ("data" in r) {
				const d = r.data;

				return d === null ? null : new Date(d);
			} else if ("aborted" in r) {
				throw new Error("request aborted by server");
			} else if ("error" in r) {
				throw new Error(r.error);
			}
		});
	}

	async setContest(contestEntry: ContestEntryViewModel, session: SessionViewModel): Promise<void> {
		const $data = new FormData();
		$data.append("xxNDN2dWA5NjFrbXxhZDQ1d2UyOGVyMW", JSON.stringify(contestEntry))
		$data.append("poNWY0YWE1azYzNjIzdnl1Y294d292dD", JSON.stringify(session))

		return await fetch(Service.toURL("BmMTR1djQ1M243bXlia2RoczdwZzR1eW"), {
			method: "post",
			credentials: "include",
			body: $data
		}).then(res => res.json()).then(r => {
			if ("error" in r) {
				throw new Error(r.error);
			}

			if ("aborted" in r) {
				throw new Error("request aborted by server");
			}
		});
	}
}
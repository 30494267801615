import { Component } from "@acryps/page";
import { PlayerViewModel, QuizService, StationViewModel } from "../managed/services";
import { Application } from "..";
import { MapOverviewComponent } from "./map-overview";
import { StationComponent } from "./station";
import { FigureSearchComponent } from "./figure-search";
import { DefaultScreenComponent } from "../shared/default-screen";
import { FeedbackComponent } from "../shared/feedback";
import { PlayerCrownComponent } from "./player-crown";

export class QuizComponent extends Component {
	station: StationViewModel;
	stations: StationViewModel[];

	activePlayer: PlayerViewModel;

	feedback = new FeedbackComponent();
	defaultScreen: DefaultScreenComponent;

	constructor(
		private stationTag: string
	) {
		super();
	}

	async onload() {
		this.station = await new QuizService().getStation(this.stationTag);
			
		if (this.station) {
			this.stations = await new QuizService().getStations();
			
			if (this.getFirstUnfinishedPlayer()) {
				this.activePlayer = this.getFirstUnfinishedPlayer();
			}
		} else {
			this.updateDefaultScreen(
				'Invalid station'
					.german('Ungültige Station')
					.french('Station invalide')
					.italian('Stazione non valida')
					.spanish('Estación no válida')
					.arabic('محطة غير صالحة')
					.korean('잘못된 스테이션')
					.japanese('無効なステーション'),
				[
					'Scan the QR code on the sign on the stele.'
						.german('Scanne den QR-Code auf dem Schild der Stele.')
						.french('Scannez le code QR sur le panneau de la stèle.')
						.italian('Scansionate il codice QR sul cartello della stele.')
						.spanish('Escanea el código QR que aparece en el cartel de la estela.')
						.arabic('امسح رمز الاستجابة السريعة الموجود على اللافتة الموجودة على الشاهدة.')
						.korean('비석에 있는 표지판에 있는 QR 코드를 스캔하세요.')
						.japanese('石碑の看板にあるQRコードをスキャンする。'),
				]
			);
		}
	}

	render() {
		if (this.defaultScreen) {
			return <ui-quiz>
				{this.defaultScreen}
			</ui-quiz>;
		}
		
		let content;

		// set content
		if (this.gameFinished() && this.station) {
			content = new FigureSearchComponent(this.station);
		} else if (this.activePlayer && this.station.gameTitle) {
			content = new StationComponent(this.station);
		} else if (this.activePlayer && !this.station.gameTitle) {
			content = new MapOverviewComponent(this.stations, this.station);
		} else {
			content = new MapOverviewComponent(this.stations, this.station.id);
		}

		return <ui-quiz>
			<ui-quiz-header>
				<ui-players>
					{Application.session.players.map(player => new PlayerCrownComponent(player))}
				</ui-players>
			</ui-quiz-header>

			{content}

			{this.feedback}
		</ui-quiz>;
	}

	// update default screen component
	updateDefaultScreen(title: string, content: string[]) {
		this.defaultScreen = new DefaultScreenComponent(title, content);
		this.defaultScreen.show();

		this.update();
	}

	// update feedback component
	updateFeedback(type: boolean, content?: string) {
		this.feedback.show(type, content);
	}

	// return true if all players have visited the provided station
	stationFinished(station: StationViewModel) {
		if (!station.gameTitle) {
			return true;
		}

		const players = Application.session.players;

		for (let player of players) {
			let visited = false;

			if (player.visits.find(visit => visit.stationId == station.id)) {
				visited = true;
			}

			if (!visited) {
				return false;
			}
		}

		return true;
	}

	// return true if all stations were visited by all players
	gameFinished() {
		for (let station of this.stations) {
			if (!this.stationFinished(station)) {
				return false;
			}
		}

		return true;
	}

	// return first player without a visit
	getFirstUnfinishedPlayer() {
		for (let player of Application.session.players) {
			if (!player.visits.find(visit => visit.stationId == this.station.id)) {
				return player;
			}
		}
	}
}
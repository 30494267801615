import { Component } from "@acryps/page";

export class DefaultScreenComponent extends Component {
	open = false;

	constructor (
		private title: string,
		private content: string[]
	) {
		super();
	}

	render() {
		if (!this.open) {
			return <ui-void></ui-void>
		}

		return <ui-default-screen>
			<ui-crown>
				<img src='/assets/images/secondary-crown.svg' />
			</ui-crown>

			<ui-title>
				{'Royal Walk Treasure Hunt Game'
					.german('Royal Walk Schatzsuche Spiel')
					.french('Jeu de chasse au trésor Royal Walk')
					.italian('Gioco della caccia al tesoro di Royal Walk')
					.spanish('Juego de búsqueda del tesoro Royal Walk')
					.arabic('لعبة البحث عن الكنز في الممشى الملكي')
					.korean('로얄 워크 보물찾기 게임')
					.japanese('ロイヤルウォーク宝探しゲーム')
				}
			</ui-title>

			<ui-information>
				<ui-title>{this.title}</ui-title>
				
				{this.content.map(text => <ui-text>
					{text}
				</ui-text>) ?? <ui-text>
					{'An error occured.'
						.german('Es ist ein Fehler aufgetreten.')
						.french(`Une erreur s'est produite.`)
						.italian('Si è verificato un errore.')
						.spanish('Se ha producido un error.')
						.arabic('حدث خطأ.')
						.korean('오류가 발생했습니다.')
						.japanese('エラーが発生しました。')
					}
				</ui-text>}
			</ui-information>
		</ui-default-screen>;
	}

	show() {
		this.open = true;

		this.update();
	}
}
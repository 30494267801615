import { Component } from "@acryps/page";
import { QuestionViewModel, QuizService, StationViewModel } from "../managed/services";
import { QuizComponent } from "./quiz";
import { Application } from "..";
import { Language } from "@acryps/language";

export class StationComponent extends Component {
	declare parent: QuizComponent;

	currentQuestion: QuestionViewModel;
	quiz;

	constructor(
		private station: StationViewModel
	) {
		super();
	}
	render() {
		this.currentQuestion = this.station.questions.find(question => question.figureId == this.parent.activePlayer.figure.id);

		return <ui-station>
			<ui-title>
				{'Treasure Hunt Game'
					.german('Schnitzeljagd Spiel')
					.french('Jeu de la chasse au trésor')
					.italian('Gioco della caccia al tesoro')
					.spanish('Juego de la búsqueda del tesoro')
					.arabic('لعبة البحث عن الكنز')
					.korean('보물 찾기 게임')
					.japanese('宝探しゲーム')
				}
			</ui-title>

			{!Application.physicallyLocalizedLanguages.includes(Language.active) && <ui-station-title>{this.station.title}</ui-station-title>}

			{!Application.physicallyLocalizedLanguages.includes(Language.active) && this.station.stationParagraphs && this.station.stationParagraphs.sort((a, b) => a.orderIndex - b.orderIndex).map(stationParagraph => <ui-station-content>
				{stationParagraph.title && <ui-title>
					{stationParagraph.title}
				</ui-title>}

				{stationParagraph.subTitle && <ui-subTitle>
					{stationParagraph.subTitle}	
				</ui-subTitle>}

				<ui-paragraph>
					{stationParagraph.paragraph}
				</ui-paragraph>
			</ui-station-content>)}

			{this.currentQuestion && <ui-quiz>
				<ui-game-title>{this.station.gameTitle}</ui-game-title>

				<ui-game-player>
					{`Task for ${this.parent.activePlayer.name}`
						.german(`Aufgabe für ${this.parent.activePlayer.name}`)
						.french(`Tâche pour ${this.parent.activePlayer.name}`)
						.italian(`Compito per ${this.parent.activePlayer.name}`)
						.spanish(`Tarea para ${this.parent.activePlayer.name}`)
						.arabic(`مهمة ل ${this.parent.activePlayer.name}`)
						.korean(`대상 작업 ${this.parent.activePlayer.name}`)
						.japanese(`課題 ${this.parent.activePlayer.name}`)
					}
				</ui-game-player>

				<ui-game-description>{this.station.gameDescription}</ui-game-description>
				<ui-question>{this.currentQuestion.name}</ui-question>

				<ui-answers ui-emoji={this.isEmojis()}>
					{this.currentQuestion.answers.map(answer => <ui-button ui-click={async () => {
						if (!answer.correct) {
							this.parent.updateFeedback(false);
						} else {
							const visit = await new QuizService().visitStation(this.parent.activePlayer.id, this.station.id);
							this.parent.activePlayer.visits.push(visit);
							this.parent.activePlayer = this.parent.getFirstUnfinishedPlayer();

							this.parent.updateFeedback(true);
						}
					}}>
						{answer.name}
					</ui-button>)}
				</ui-answers>
			</ui-quiz>}
		</ui-station>;
	}

	isEmojis() {
		for (let answer of this.currentQuestion.answers) {
			// ignore japanese characters
			if (/([\u3040-\u309F]|[\u30A0-\u30FF]|[\u31F0-\u31FF])/.test(answer.name.toString())) {
				return false;
			}

			// detect emojis
			if (/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/.test(answer.name.toString())) {
				return true;
			}
		}

		return false;
	}
}
import { percentage, child, position, inset, zIndex, display, flexDirection, flexGrow, alignItems, justifyContent, marginInline, marginBlock, paddingInline, paddingBlock, borderRadius, backgroundColor, width, marginTop, rem, fontSize, textAlign, style, marginBottom, color, fontWeight, pointerEvents, opacity, aspectRatio, lineHeight, borderTopWidth, borderLeftWidth, borderRightWidth, borderBottomWidth, textDecorationLine, min, ratio, left, alignContent, border, height, hex, px, top, justifySelf, vw } from "@acryps/style";
import { quizIndex, pageSpacingInline, pageSpacingBlock, pageBorderRadius, headerFont, titleFontSize, subTitleFontSize, feedbackIndex, primaryContrastColor, textFontSize, secondaryContrastColor, primaryColor, panelBackgroundColor, contentFont, contentSpacing, subHeaderFont, stationDotSize, stationPointColor, stationPointDisabledColor, stationPointFinishedColor, crownAspectRatio, pageWidthMax } from "../index.style";
import { stationX, stationY } from "../quiz/quiz.style";

// default screen component styling
export const defaultScreenStyle = () => child('ui-default-screen',
	position('fixed'),
	inset(0),
	
	zIndex(quizIndex),

	display('flex'),
	flexDirection('column'),
	flexGrow(1),
	alignItems('center'),
	justifyContent('center'),

	// subtract padding and margin from width
	width(min(pageWidthMax, vw(100).subtract(pageSpacingInline.multiply(4)))),
	justifySelf('center'),

	marginInline(pageSpacingInline),
	marginBlock(pageSpacingBlock),
	paddingInline(pageSpacingInline),
	paddingBlock(pageSpacingBlock),

	borderRadius(pageBorderRadius),
	backgroundColor(panelBackgroundColor),

	child('ui-crown',
		width(rem(12)),
		
		aspectRatio(ratio(1.7868852459, 1)),
		fontSize(0),

		child('img',
			width(percentage(100))
		)
	),

	child('ui-title',
		display('block'),
		marginTop(contentSpacing.divide(2)),
		textAlign('center'),

		titleStyle()
	),

	child('ui-information',
		display('block'),
		width(min(percentage(100), rem(40))),
		marginTop(contentSpacing),
		
		textAlign('center'),
		fontSize(subTitleFontSize),

		child('ui-title',
			display('block'),
			
			marginBottom(contentSpacing.divide(2)),
			textAlign('center'),

			subHeaderFont,
		),

		child('ui-text',
			display('block'),

			style(':not(:last-child)',
				marginBottom(rem(0.5)),
			),
		)
	)
);

// feedback component styling
export const feedBackStyle = () => child('ui-feedback',
	position('fixed'),
	inset(0),
	zIndex(feedbackIndex),

	display('flex'),
	justifyContent('center'),

	backgroundColor(panelBackgroundColor),

	child('ui-content',
		display('flex'),
		justifyContent('center'),
		
		// subtract padding and margin from width
		width(min(pageWidthMax, vw(100).subtract(pageSpacingInline))),

		child('img',
			width(percentage(35))
		),
		
		child('ui-text',
			alignContent('center'),
			textAlign('center'),
			
			titleStyle()
		)
	)
);

// base for crown styling
export const crownStyle = () => [
	position('relative'),

	display('flex'),
	flexDirection('column'),

	alignItems('center'),
	justifyContent('end'),

	aspectRatio(crownAspectRatio),

	child('ui-crown',
		width(percentage(100)),

		fontSize(0),
		aspectRatio(crownAspectRatio),

		child('svg',
			width(percentage(100)),
			height('auto')
		),

		child('img',
			width(percentage(100))
		)
	),

	child('ui-content',
		position('absolute'),

		display('flex'),
		flexDirection('column'),
		
		color(secondaryContrastColor),
	)
];

// base styling for station content
export const stationContentStyle = () => [
	marginTop(contentSpacing),

	child('ui-title',
		display('block'),
		marginBottom(contentSpacing.divide(2)),

		fontSize(rem(1.25)),
		fontWeight('bold'),
	),

	child('ui-subTitle',
		display('block'),
		marginBottom(contentSpacing.divide(2))
	),

	child('ui-paragraph',
		display('block'),
	)
];

// map overview styling since use in different locations
export const mapOverviewStyle = () => [
	child('ui-station-title',
		subTitleStyle(),
	),

	child('ui-station-content',
		stationContentStyle(),

		child('ui-paragraph',
			marginBottom(contentSpacing)
		)
	),

	child('ui-title',
		titleStyle(),
	),

	child('ui-text',
		marginBlock(contentSpacing),
		fontSize(textFontSize)
	),

	child('ui-map',
		position('relative'),
		fontSize(0),

		child('ui-station-point',
			position('absolute'),
			left(percentage(100).multiply(stationX).subtract(stationDotSize.divide(2))),
			top(percentage(100).multiply(stationY).subtract(stationDotSize.divide(2))),

			width(stationDotSize),
			height(stationDotSize),

			border(px(4), "solid", hex('#000')),
			borderRadius(percentage(50)),

			backgroundColor(stationPointColor)
		)
			.attribute('ui-finished',
				backgroundColor(stationPointFinishedColor),
			)
			.attribute('ui-disabled',
				backgroundColor(stationPointDisabledColor)
			),
		
		child('img',
			width(percentage(100)),
			borderRadius(pageBorderRadius)
		)
	),

	child('ui-information',
		marginTop(contentSpacing),
		fontSize(subTitleFontSize)
	)
];

// base for panels
export const panelBase = () => [
	zIndex(quizIndex),

	display('flex'),
	flexDirection('column'),
	flexGrow(1),

	paddingInline(pageSpacingInline),
	paddingBlock(pageSpacingBlock),
	
	fontSize(textFontSize),
	
	borderRadius(pageBorderRadius),
	backgroundColor(panelBackgroundColor),
];

// image styling
export const imageStyle = () => [
	width(percentage(100)),
	borderRadius(pageBorderRadius)
];

export const horizontalImageStyle = () => [
	imageStyle(),
	aspectRatio(ratio(16, 9)),
];

export const squareImageStyle = () => [
	imageStyle(),
	aspectRatio(ratio(1, 1)),
];

// title styling
export const titleStyle = () => [
	headerFont,

	lineHeight(1.1),
	fontSize(titleFontSize),
	color(primaryColor),
];

export const subTitleStyle = () => [
	subHeaderFont,

	lineHeight(1),
	fontSize(rem(1.75)),
];

// button styling
const buttonBase = () => [
	paddingBlock(rem(1)),
	textAlign('center'),
	borderRadius(pageBorderRadius),

	fontSize(textFontSize),
	fontWeight('bold'),
];

export const buttonStyle = () => [
	buttonBase(),

	backgroundColor(primaryColor),
	color(primaryContrastColor),

	style('[ui-disabled]',
		pointerEvents('none'),
		opacity(percentage(50)),
	)
];

export const inlineButtonStyle = () => [
	buttonBase(),

	color(primaryColor),
	textDecorationLine('underline')
];

// input field styling
export const inputStyle = () => [
	paddingBlock(rem(0.75)),
	paddingInline(rem(1)),

	contentFont,
	fontSize(textFontSize),

	borderRadius(pageBorderRadius),
	borderTopWidth(0),
	borderLeftWidth(0),
	borderRightWidth(0),
	borderBottomWidth(0),

	style(':not(:last-child)',
		marginBottom(rem(0.5))
	)
];
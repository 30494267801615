import { Component } from "@acryps/page";
import { Application } from "..";
import { ContestEntryViewModel, QuizService, StationViewModel } from "../managed/services";
import { figureCodeLength } from "./quiz.style";
import { QuizComponent } from "./quiz";
import { Language } from "@acryps/language";

export class FigureSearchComponent extends Component {
	declare parent: QuizComponent;
	
	codes: Record<string, string> = {};
	entry = new ContestEntryViewModel();

	errorMessage: string;

	germanLink = 'https://www.hfsjg.ch/de/forschung-auf-jungfraujoch/forschungsschwerpunkte/';
	englishLink = 'https://www.hfsjg.ch/en/jungfraujoch/research-topics/';

	constructor(
		private station: StationViewModel
	){
		super();
	}

	onload() {
		for (let player of Application.session.players) {
			if (player.unlockedAt) {
				this.codes[player.id] = player.figure.code;
			} else {
				this.codes[player.id] = '';
			}
		}
	}

	render() {
		// show default screen when game is finished
		if (localStorage.getItem(Application.sessionEntrySubmitted)) {
			this.parent.updateDefaultScreen(
				'Congratulations!'
					.german('Glückwunsch!')
					.french('Félicitations!')
					.italian('Congratulazioni!')
					.spanish('¡Felicidades!')
					.arabic('تهانينا!')
					.korean('축하합니다!')
					.japanese('おめでとう！'),
				[
					`It's great that you played along.`
						.german('Toll, dass du mitgespielt hast.')
						.french(`C'est formidable que vous ayez joué le jeu.`)
						.italian('È bello che tu sia stato al gioco.')
						.spanish('Es estupendo que me hayas seguido el juego.')
						.arabic('من الرائع أنك لعبت دورًا رائعًا.')
						.korean('함께 해주셔서 감사합니다.')
						.japanese('あなたが一緒にプレーしてくれたことは素晴らしい。')
				]
			);
		}

		if (Application.session.players.every(player => player.unlockedAt)) {
			return <ui-figure-search>
				<ui-title>
					{'The Sphinx Observatory'
						.german('Das Sphinx-Observatorium')
						.french(`L'Observatoire du Sphinx`)
						.italian(`L'Osservatorio della Sfinge`)
						.spanish('Observatorio de la Esfinge')
						.arabic('مرصد أبو الهول')
						.korean('스핑크스 전망대')
						.japanese('スフィンクス展望台')
					}
				</ui-title>

				<ui-subtitle>
					{'The treasure of the Jungfraujoch'
						.german('Der Schatz des Jungfraujochs')
						.french(`Le trésor de la Jungfraujoch`)
						.italian(`Il tesoro dello Jungfraujoch`)
						.spanish('El tesoro del Jungfraujoch')
						.arabic('كنز يونغفرايوخ يونغفرايوخ')
						.korean('융프라우요흐의 보물')
						.japanese('ユングフラウヨッホの宝物')
					}
				</ui-subtitle>

				<ui-image>
					<img src='/assets/images/sphynx.svg' />
				</ui-image>

				<ui-text>
					{`The Sphinx Observatory on the top of the rocky peak of the same name is one of the most modern research stations in the world and focuses on environmental and climate research. For example in meteorology, which began in 1925 with a meteorological pavilion on the glacier. Since 1980, data has been automatically recorded and sent by the Sphinx station, Europe's highest weather station, which is permanently manned. This enables precise measurements and important weather observations, which are regularly transmitted to MeteoSwiss. The weather data from the Jungfraujoch is crucial for weather forecasts and important parameters for environmental and climate research. Long-term measurements of temperature and wind provide important insights into climate change, which is particularly noticeable in the Alpine region.`
						.german('Das Sphinx-Observatorium auf der Spitze der gleichnamigen Felskuppe gehört zu den modernsten Forschungsstationen weltweit und konzentriert sich auf Umwelt- und Klimaforschung. Zum Beispiel in der Meteorologie, die 1925 mit einem meteorologischen Pavillon auf dem Gletscher begann. Seit 1980 werden die Daten automatisch von der Sphinx-Station erfasst und versandt, wobei diese höchste Wetterstation Europas ständig bemannt ist. Dies ermöglicht präzise Messungen und wichtige Wetterbeobachtungen, die regelmäßig an MeteoSchweiz übermittelt werden. Die Wetterdaten des Jungfraujochs sind entscheidend für Wettervorhersagen und wichtige Parameter für die Umwelt- und Klimaforschung. Langzeitmessungen zu Temperatur und Wind liefern wesentliche Erkenntnisse über den Klimawandel, der im alpinen Raum besonders spürbar ist.')
						.french(`L'Observatoire du Sphinx, situé au sommet du pic rocheux du même nom, est l'une des stations de recherche les plus modernes au monde et se concentre sur la recherche environnementale et climatique. Par exemple, dans le domaine de la météorologie, qui a débuté en 1925 avec un pavillon météorologique sur le glacier. Depuis 1980, les données sont collectées et envoyées automatiquement par la station Sphinx, et cette station météorologique, la plus haute d'Europe, est habitée en permanence. Cela permet des mesures précises et des observations météorologiques importantes qui sont régulièrement transmises à MétéoSuisse. Les données météorologiques du Jungfraujoch sont déterminantes pour les prévisions météorologiques et constituent des paramètres importants pour la recherche environnementale et climatique. Les mesures à long terme de la température et du vent fournissent des informations essentielles sur le changement climatique, qui est particulièrement perceptible dans les régions alpines.`)
						.italian(`L'Osservatorio della Sfinge, sulla cima dell'omonimo picco roccioso, è una delle stazioni di ricerca più moderne al mondo ed è specializzato nella ricerca ambientale e climatica. Ad esempio nella meteorologia, iniziata nel 1925 con un padiglione meteorologico sul ghiacciaio. Dal 1980, i dati vengono registrati e inviati automaticamente dalla stazione Sphinx, la stazione meteorologica più alta d'Europa, presidiata in permanenza. Ciò consente misurazioni precise e importanti osservazioni meteorologiche, che vengono regolarmente trasmesse a MeteoSvizzera. I dati meteorologici provenienti dallo Jungfraujoch sono fondamentali per le previsioni del tempo e importanti parametri per la ricerca ambientale e climatica. Le misurazioni a lungo termine di temperatura e vento forniscono importanti indicazioni sui cambiamenti climatici, particolarmente evidenti nella regione alpina.`)
						.spanish(`El Observatorio de la Esfinge, en la cima del pico rocoso del mismo nombre, es una de las estaciones de investigación más modernas del mundo y se centra en la investigación medioambiental y climática. Por ejemplo en meteorología, que comenzó en 1925 con un pabellón meteorológico en el glaciar. Desde 1980, los datos son registrados y enviados automáticamente por la estación Esfinge, la estación meteorológica más alta de Europa, que está permanentemente tripulada. Esto permite realizar mediciones precisas e importantes observaciones meteorológicas, que se transmiten regularmente a MeteoSwiss. Los datos meteorológicos del Jungfraujoch son cruciales para las previsiones meteorológicas y parámetros importantes para la investigación medioambiental y climática. Las mediciones a largo plazo de la temperatura y el viento proporcionan información importante sobre el cambio climático, que es particularmente notable en la región alpina.`)
						.arabic('مرصد سفنكس على قمة القمة الصخرية التي تحمل نفس الاسم هو أحد أحدث المحطات البحثية في العالم ويركز على الأبحاث البيئية والمناخية. على سبيل المثال في مجال الأرصاد الجوية، والتي بدأت في عام 1925 بجناح للأرصاد الجوية على الجبل الجليدي. ومنذ عام 1980، يتم تسجيل البيانات وإرسالها آلياً من خلال محطة سفنكس، وهي أعلى محطة أرصاد جوية في أوروبا، وهي محطة مأهولة بشكل دائم. ويتيح ذلك إجراء قياسات دقيقة وملاحظات هامة عن الطقس، والتي يتم إرسالها بانتظام إلى محطة الأرصاد الجوية السويسرية. تُعد بيانات الطقس من محطة يونغفرايوخ بالغة الأهمية للتنبؤات الجوية والمعايير الهامة للأبحاث البيئية والمناخية. وتوفر القياسات طويلة الأجل لدرجات الحرارة والرياح رؤى مهمة حول تغير المناخ، وهو أمر ملحوظ بشكل خاص في منطقة جبال الألب.')
						.korean('같은 이름의 바위 봉우리 꼭대기에 있는 스핑크스 천문대는 세계에서 가장 현대적인 연구 기지 중 하나이며 환경 및 기후 연구에 중점을 두고 있습니다. 예를 들어, 1925년 빙하 위에 기상관으로 시작된 기상학 분야가 그 예입니다. 1980년부터는 유럽에서 가장 높은 기상 관측소인 스핑크스 관측소에서 데이터를 자동으로 기록하여 전송하고 있으며, 이 관측소는 영구적으로 유인 운영되고 있습니다. 이를 통해 정밀한 측정과 중요한 기상 관측이 가능하며, 이 데이터는 MeteoSwiss로 정기적으로 전송됩니다. 융프라우요흐의 기상 데이터는 일기 예보와 환경 및 기후 연구를 위한 중요한 매개 변수에 매우 중요합니다. 온도와 바람의 장기 측정은 기후 변화에 대한 중요한 통찰력을 제공하며, 이는 특히 알프스 지역에서 두드러지게 나타납니다.')
						.japanese('同じ名前の岩山の頂上にあるスフィンクス天文台は、世界で最も近代的な研究ステーションのひとつで、環境と気候の研究に重点を置いている。たとえば気象学では、1925年に氷河の上に気象パビリオンを設置して始まった。1980年以来、データは自動的に記録され、ヨーロッパで最も高い場所にあるスフィンクス測候所から送信されている。これにより、正確な測定と重要な気象観測が可能になり、定期的にメテオスイスに送信されている。ユングフラウヨッホの気象データは、天気予報や環境・気候研究の重要なパラメータとして欠かせない。気温と風の長期測定は、アルプス地方で特に顕著な気候変動に関する重要な洞察を提供している。')
					}
				</ui-text>

				<ui-read-more>
					<a href={Language.active.id == 'de' ? this.germanLink : this.englishLink} target="_blank">
						{'Find out more'
							.german('Erfahre mehr')
							.french('Pour en savoir plus')
							.italian('Per saperne di più')
							.spanish('Más información')
							.arabic('اكتشف المزيد')
							.korean('자세히 알아보세요')
							.japanese('詳細はこちら')
						}
					</a>
				</ui-read-more>

				<ui-contest>
					<ui-image>
						<img src='/assets/images/sackmesser.jpg' />
					</ui-image>

					<ui-title>
						{'Competition'
							.german('Wettbewerb')
							.french('Compétition')
							.italian('Concorso')
							.spanish('Concurso')
							.arabic('المنافسة')
							.korean('경쟁')
							.japanese('コンペティション')
						}
					</ui-title>

					<ui-text>
						{'For all successful treasure hunters, we are giving away a Victorinox Climber Jungfrau pocket knife every month.'
							.german('Für alle erfolgreichen Schatzjäger verlosen wir jeden Monat ein Victorinox Climber Jungfrau Taschenmesser.')
							.french('Pour tous les chasseurs de trésors, nous offrons chaque mois un couteau de poche Victorinox Climber Jungfrau.')
							.italian('Per tutti i cacciatori di tesori di successo, ogni mese mettiamo in palio un coltello tascabile Victorinox Climber Jungfrau.')
							.spanish('Para todos los cazadores de tesoros que tengan éxito, regalamos una navaja Victorinox Climber Jungfrau cada mes.')
							.arabic('لكل الباحثين عن الكنوز الناجحين في البحث عن الكنوز، نقدم سكين جيب فيكتورينوكس كلايمبر جونغفراو كل شهر.')
							.korean('보물 찾기에 성공한 모든 분들께 매달 빅토리녹스 클라이머 융프라우 포켓 나이프를 증정하고 있습니다.')
							.japanese('宝探しに成功した方には、ビクトリノックスのクライマー・ユングフラウ・ポケットナイフを毎月プレゼント。')
						}					
					</ui-text>

					<ui-text>
						{'Take part and enter your name and email so we can contact you if you win!'
							.german('Nimm teil und gib deinen Namen und deine Email an, damit wir dich erreichen können, wenn du gewinnst!')
							.french('Participez et indiquez votre nom et votre adresse électronique afin que nous puissions vous contacter si vous gagnez !')
							.italian('Partecipa e inserisci il tuo nome e la tua e-mail per poterti contattare in caso di vincita!')
							.spanish('Participa e introduce tu nombre y correo electrónico para que podamos ponernos en contacto contigo si ganas.')
							.arabic('شارك وأدخل اسمك وبريدك الإلكتروني حتى نتمكن من الاتصال بك إذا فزت!')
							.korean('참여하여 이름과 이메일을 입력하면 당첨 시 연락을 드릴 수 있습니다!')
							.japanese('お名前とEメールアドレスを入力してください！')
						}					
					</ui-text>

					<ui-text>
						{'Your personal data will only be stored for the purpose of participating in the competition and will be deleted after the prize draw.'
							.german('Deine persönlichen Daten werden ausschliesslich für die Teilnahme am Gewinnspiel gespeichert und nach der Verlosung gelöscht.')
							.french('Vos données personnelles ne seront stockées que dans le but de participer au concours et seront supprimées après le tirage au sort.')
							.italian(`I dati personali saranno conservati solo ai fini della partecipazione al concorso e saranno cancellati dopo l'estrazione dei premi.`)
							.spanish('Sus datos personales sólo se almacenarán con el fin de participar en el concurso y se eliminarán después del sorteo.')
							.arabic('لن يتم تخزين بياناتك الشخصية إلا لغرض المشاركة في المسابقة وسيتم حذفها بعد السحب على الجائزة.')
							.korean('귀하의 개인 데이터는 대회 참가 목적으로만 저장되며 경품 추첨 후 삭제됩니다.')
							.japanese('お客様の個人情報は、懸賞に参加する目的でのみ保存され、懸賞の抽選後に削除されます。')
						}					
					</ui-text>
					
					<ui-forms>
						{this.errorMessage && <ui-error-message>
							{this.errorMessage}
						</ui-error-message>}

						<input $ui-value={Component.accessor(() => this.entry.firstName, $$value => this.entry.firstName = $$value)} placeholder={
							`First name`
								.german(`Vorname`)
								.french(`Prénom`)
								.italian(`Nome`)
								.spanish('Nombre')
								.arabic('الاسم الأول')
								.korean('이름')
								.japanese('名前')
						} />
						<input $ui-value={Component.accessor(() => this.entry.lastName, $$value => this.entry.lastName = $$value)} placeholder={
							`Surname`
								.german(`Nachname`)
								.french(`Nom de famille`)
								.italian(`Cognome`)
								.spanish('Apellido')
								.arabic('اللقب')
								.korean('성')
								.japanese('苗字')
						} />
						<input $ui-value={Component.accessor(() => this.entry.emailAddress, $$value => this.entry.emailAddress = $$value)} placeholder={
							`E-Mail`
								.german(`E-Mail`)
								.french(`E-Mail`)
								.italian(`E-Mail`)
								.spanish('E-Mail')
								.arabic('البريد الإلكتروني')
								.korean('이메일')
								.japanese('Eメール')
						} />
					</ui-forms>
				</ui-contest>

				<ui-buttons-container>
					<ui-button ui-click={async () => {
						// save quiz data in database if fields were filled out
						if (!this.entry.firstName || !this.entry.lastName || !this.entry.emailAddress) {
							this.errorMessage = 'Please fill out your information to participate'
								.german('Bitte füllen Sie Ihre Informationen zur Teilnahme aus')
								.french('Veuillez remplir vos coordonnées pour participer')
								.italian('Compilare i dati per partecipare')
								.spanish('Rellene sus datos para participar')
								.arabic('يرجى ملء بياناتك للمشاركة')
								.korean('참여하려면 정보를 입력해 주세요.')
								.japanese('参加ご希望の方は必要事項をご記入ください。');

							this.update();
						} else {	
							this.parent.updateFeedback(true, 
								'Thank you for your participation'
									.german('Danke für die Teilnahme')
									.french('Merci de votre participation')
									.italian('Grazie per la vostra partecipazione')
									.spanish('Gracias por su participación')
									.arabic('شكراً لك على مشاركتك')
									.korean('참여해주셔서 감사합니다.')
									.japanese('ご参加ありがとうございました')
							);

							await new QuizService().setContest(this.entry, Application.session);
							localStorage.setItem(Application.sessionEntrySubmitted, 'submitted');
						}
					}}>{`Participate`
							.german(`Teilnehmen`)
							.french(`Participer`)
							.italian(`Partecipare`)
							.spanish('Participe en')
							.arabic('شارك')
							.korean('참여하기')
							.japanese('参加する')
					}</ui-button>

					<ui-button ui-click={async () => {
						await new QuizService().setContest(this.entry, Application.session);
						localStorage.setItem(Application.sessionEntrySubmitted, 'submitted');

						this.update();
					}}>{`Do not participate`
						.german(`Nicht Teilnehmen`)
						.french(`Ne pas participer`)
						.italian(`Non partecipare`)
						.spanish('No participe')
						.arabic('لا تشارك')
						.korean('참여하지 않음')
						.japanese('参加しない')
					}</ui-button>
				</ui-buttons-container>
			</ui-figure-search>;
		}

		return <ui-figure-search>
			<ui-title>
				{'Treasure Hunt Game'
					.german('Schnitzeljagd Spiel')
					.french('Jeu de la chasse au trésor')
					.italian('Gioco della caccia al tesoro')
					.spanish('Juego de la búsqueda del tesoro')
					.arabic('لعبة البحث عن الكنز')
					.korean('보물 찾기 게임')
					.japanese('宝探しゲーム')
				}
			</ui-title>

			{!this.station.gameTitle && !Application.physicallyLocalizedLanguages.includes(Language.active) && <ui-station-title>{this.station.title}</ui-station-title>}

			{!this.station.gameTitle && !Application.physicallyLocalizedLanguages.includes(Language.active) && this.station.stationParagraphs && this.station.stationParagraphs.sort((a, b) => a.orderIndex - b.orderIndex).map(stationParagraph => <ui-station-content>
				{stationParagraph.title && <ui-title>
					{stationParagraph.title}
				</ui-title>}

				{stationParagraph.subTitle && <ui-subTitle>
					{stationParagraph.subTitle}	
				</ui-subTitle>}

				<ui-paragraph>
					{stationParagraph.paragraph}
				</ui-paragraph>
			</ui-station-content>)}

			<ui-search-title>
				{'Find the secret code'
					.german('Finde den Geheimcode')
					.french('Trouver le code secret')
					.italian('Trova il codice segreto')
					.spanish('Encuentra el código secreto')
					.arabic('اعثر على الرمز السري')
					.korean('비밀 코드 찾기')
					.japanese('秘密のコードを見つけよう')
				}
			</ui-search-title>

			<ui-introduction>
				{`Bravo, you've solved all the tasks!`
					.german('Bravo, du hast alle Aufgaben gelöst!')
					.french('Bravo, tu as résolu toutes les tâches!')
					.italian('Bravo, hai risolto tutti i compiti!')
					.spanish('Bravo, ¡has resuelto todas las tareas!')
					.arabic('أحسنت، لقد حللت جميع المهام!')
					.korean('브라보, 모든 과제를 해결했습니다!')
					.japanese('ブラボー、あなたはすべての課題を解いた！')
				}
			</ui-introduction>

			<ui-text>
				{`Now you just have to find the secret code to open the treasure. The symbol shown above is hidden at the cable car mountain station or the crown platform. Look for it and get the code.`
					.german('Nun musst du nur noch den Geheimcode finden, um den Schatz zu öffnen. Bei der Luftseilbahn-Bergstation oder der Kronenplattform ist das oben abgebildete Symbol versteckt. Suche es und hole dir den Code.')
					.french(`Il ne te reste plus qu'à trouver le code secret pour ouvrir le trésor. Le symbole ci-dessus est caché dans la station de montagne du téléphérique ou sur la plate-forme de la couronne. Cherche-le et obtiens le code.`)
					.italian('Ora dovete solo trovare il codice segreto per aprire il tesoro. Il simbolo qui sopra è nascosto nella stazione a monte della funivia o sulla piattaforma della corona. Cercatelo e trovate il codice.')
					.spanish('Ahora sólo tienes que encontrar el código secreto para abrir el tesoro. El símbolo que se muestra arriba está escondido en la estación de montaña del teleférico o en la plataforma de la corona. Búscalo y consigue el código.')
					.arabic(' الآن عليك فقط العثور على الرمز السري لفتح الكنز. الرمز الموضح أعلاه مخفي في محطة جبل التلفريك أو منصة التاج. ابحث عنه واحصل على الرمز.')
					.korean('이제 보물을 열기 위한 비밀 암호를 찾기만 하면 됩니다. 위에 표시된 기호는 케이블카 산악 역이나 크라운 플랫폼에 숨겨져 있습니다. 그것을 찾아서 코드를 받으세요.')
					.japanese('あとは宝物を開ける秘密のコードを見つけるだけです。上のシンボルは、ケーブルカーの山頂駅か王冠のプラットフォームに隠されています。それを探してコードをゲットしてください。')
				}	
			</ui-text>

			{Application.session.players.map(player => <ui-figure dir='ltr'>
				<ui-figure-icon>{this.figureImage(player.figure.icon)}</ui-figure-icon>

				<ui-player-information>
					<ui-name>{player.name}</ui-name>

					<ui-figure-code style={`${figureCodeLength.provide(player.figure.code.length)}`}>
							<ui-input-container>
								<input
									maxLength={player.figure.code.length}
									$ui-value={Component.accessor(() => this.codes[player.id], $$value => this.codes[player.id] = $$value)}
									ui-disabled={!!player.unlockedAt}
									ui-change={async () => {
										// check if already submitted
										if (!player.unlockedAt && this.codes[player.id].length == player.figure.code.length) {
											if (this.codes[player.id] == player.figure.code) {
												this.parent.updateFeedback(true, 
													'Treasure cracked'
														.german('Schatz geknackt')
														.french('Trésor fissuré')
														.italian('Hai trovato il tesoro')
														.spanish('Tesoro resquebrajado')
														.arabic('كنز متصدع')
														.korean('금이 간 보물')
														.japanese('割れた宝物')
												);

												player.unlockedAt = await new QuizService().codeUnlocked(player);
											} else {	
												this.update();
											}
										}
									}}
								/>
							</ui-input-container>

							<ui-code-overlay
								ui-error={this.codes[player.id].length == player.figure.code.length && this.codes[player.id] != player.figure.code}
								ui-found={!!player.unlockedAt}
							>
								<ui-character></ui-character>
								<ui-character></ui-character>
								<ui-character></ui-character>
								<ui-character></ui-character>
							</ui-code-overlay>
					</ui-figure-code>
				</ui-player-information>
			</ui-figure>)}
		</ui-figure-search>;
	}

	figureImage(figure: string) {
		const proxy = document.createElement('ui-proxy');
		proxy.innerHTML = figure;
	
		return proxy.querySelector('svg');
	};
}
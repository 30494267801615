import { FontDimension, Variable, alignItems, backgroundColor, border, borderRadius, borderRight, ch, child, color, display, fill, flexDirection, flexGrow, flexShrink, fontSize, height, hex, justifyContent, letterSpacing, lineHeight, margin, marginBlock, marginBottom, marginInline, marginLeft, marginRight, marginTop, min, objectFit, opacity, outline, overflow, padding, paddingBlock, paddingBottom, paddingInline, paddingRight, percentage, pointerEvents, position, px, rem, scale, style, textDecoration, textOverflow, textTransform, textWrap, transform, transformOrigin, width, zIndex } from "@acryps/style";
import { contentSpacing, pageSpacingBlock, pageSpacingInline, primaryColor, titleFontSize, playerFontSize, quizIndex, pageBorderRadius, panelBackgroundColor, borderColor, transparentColor, figureSearchFontSize, errorColor, monospaceFont, successColor, subHeaderFont, primaryContrastColor } from "../index.style";
import { buttonStyle, crownStyle, defaultScreenStyle, feedBackStyle, horizontalImageStyle, imageStyle, inputStyle, mapOverviewStyle, panelBase, stationContentStyle, subTitleStyle, titleStyle } from "../shared/shared.style";

const stationCount = 6;
const diamondSpacing = rem(0.1);
const playerSpacing = rem(0.5);
const figureImageSpacing = rem(0.5);

export const stationX = new Variable<number>('x');
export const stationY = new Variable<number>('y');
export const figureCodeLength = new Variable<number>('code-length');

export const namePlateFontSize = new Variable<FontDimension>('font-size');

export const quizStyle = () => child('ui-quiz',
	display('flex'),
	flexDirection('column'),

	paddingInline(pageSpacingInline),
	paddingBlock(pageSpacingBlock),

	child('ui-quiz-header',
		zIndex(quizIndex),

		display('flex'),
		paddingInline(rem(0.25)),

		child('ui-players',
			display('flex'),
			flexGrow(1),
			justifyContent('space-around'),

			marginLeft(playerSpacing),

			child('ui-crown-container',
				crownStyle(),
				width(min(percentage(100).divide(3), rem(10))),
				
				style(':not(:last-child)',
					marginRight(playerSpacing),
				),

				// active player
				style('[ui-active]',
					pointerEvents('none'),

					child('ui-crown',
						transform(scale(1.15)),
						transformOrigin('center', 'bottom')
					)
				),

				// disabled player when finished answering
				style('[ui-disabled]',
					pointerEvents('none'),
				),

				child('ui-crown',
					opacity(panelBackgroundColor.alpha)
				),

				child('ui-content',	
					// set content width to crown edge
					width(percentage(100).subtract(rem(0.75))),
					height(percentage(30)),

					alignItems('center'),

					lineHeight(1),
					fontSize(playerFontSize),
					
					child('ui-name',
						display('flex'),
						flexGrow(1),
						alignItems('end'),
						justifyContent('center'),
						width(percentage(90)),
						
						textWrap('nowrap'),
						fontSize(min(playerFontSize, namePlateFontSize))
					),

					child('ui-stage',
						display('flex'),
						flexDirection('row'),

						marginTop('auto'),
						marginInline(rem(0.5)),
						marginBottom(rem(0.5)),

						child('img',
							width(percentage(100).divide(stationCount).subtract(diamondSpacing.multiply(2))),
							marginInline(diamondSpacing)
						),

						child('ui-filler',
							width(percentage(100).divide(stationCount).subtract(diamondSpacing.multiply(2))),
							marginInline(diamondSpacing)
						)
					)
				),
			)
		)
	),

	child('ui-station',
		panelBase(),
		
		child('ui-title',
			titleStyle(),
		),

		child('ui-station-title',
			subTitleStyle(),
			marginTop(contentSpacing),
		),

		child('ui-station-content',
			stationContentStyle(),

			style(':last-of-type',
				marginBottom(contentSpacing)
			)
		),

		child('ui-quiz',
			display('flex'),
			flexDirection('column'),
			
			child('ui-game-title',
				subTitleStyle(),
				marginBlock(contentSpacing),
			),

			child('ui-game-player',
				marginBottom(contentSpacing),
				subHeaderFont
			),

			child('ui-game-description',
				marginBottom(contentSpacing.divide(2)),
			),

			child('ui-question',
				marginBottom(contentSpacing),
				subHeaderFont
			),

			child('ui-answers',
				display('flex'),
				flexDirection('column'),
				width(percentage(100)),
				
				child('ui-button',
					buttonStyle(),

					paddingInline(rem(0.75)),
					subHeaderFont,

					style(':not(:last-child)',
						marginBottom(contentSpacing.divide(2)),
					)
				),
			)
				.attribute('ui-emoji',
					child('ui-button',
						fontSize(rem(2)),
						letterSpacing(rem(0.5))
					)
				),
		),
	),

	child('ui-map-overview',
		mapOverviewStyle(),
		panelBase(),
	),

	child('ui-figure-search',
		panelBase(),

		child('ui-title',
			titleStyle()
		),

		child('ui-station-title',
			subTitleStyle(),
			marginTop(contentSpacing),
		),

		child('ui-station-content',
			stationContentStyle(),

			style(':last-of-type',
				marginBottom(contentSpacing)
			)
		),

		child('ui-search-title',
			subTitleStyle(),
			marginTop(contentSpacing),
		),

		child('ui-subtitle',
			subTitleStyle(),

			marginBlock(contentSpacing.divide(2))
		),

		child('ui-introduction',
			marginTop(contentSpacing),
			marginBottom(contentSpacing.divide(2)),
			subHeaderFont
		),

		child('ui-image',
			marginBottom(contentSpacing),
			fontSize(0),

			child('img',
				imageStyle(),
			)
		),

		child('ui-text',
			marginBottom(contentSpacing),
		),

		child('ui-read-more',
			marginBottom(contentSpacing),

			child('a',
				display('block'),

				color('currentColor'),
				textDecoration('none'),
			)
		),

		child('ui-figure',
			display('flex'),
			alignItems('center'),
			marginBottom(contentSpacing.divide(2)),
			overflow('hidden'),

			child('ui-figure-icon',
				display('flex'),

				width(min(percentage(100).divide(3), rem(8))),
				paddingRight(rem(1)),

				flexShrink(0),

				child('svg',
					width(percentage(100).subtract(figureImageSpacing.multiply(2))),
					height('auto'),

					padding(figureImageSpacing),

					backgroundColor(primaryColor),
					borderRadius(pageBorderRadius),
					fill(primaryContrastColor),
				)
			),

			child('ui-player-information',
				marginRight(ch(1).multiply(-1)),

				child('ui-name',
					fontSize(titleFontSize),
					textTransform('uppercase'),
				),
			
				child('ui-figure-code',
					position('relative'),
					
					display('flex'),
					width(ch(2).multiply(figureCodeLength)),
					paddingRight(ch(1)),

					fontSize(figureSearchFontSize),
					monospaceFont,

					child('ui-input-container',
						display('flex'),
						marginRight(ch(1).multiply(-1)),

						child('input',
							zIndex(1),
							display('flex'),
							width(percentage(100).add(rem(2))),

							margin(0),
							paddingInline(ch(1).divide(2)),
							
							letterSpacing(ch(1)),
							fontSize(figureSearchFontSize),
							monospaceFont,
						
							border(px(0), 'solid', hex('#fff')),
							backgroundColor(transparentColor),

							style(':focus',
								textOverflow('clip'),
								outline(0, 'none', hex('#fff')),
							)
						)
							.attribute('ui-disabled',
								pointerEvents('none')
							),
					),

					child('ui-code-overlay',
						position('absolute'),
						pointerEvents('none'),

						display('flex'),
						width(percentage(100).subtract(px(2)).subtract(ch(1))),
						height(percentage(100).subtract(px(2))),

						border(px(1), 'solid', borderColor),
						backgroundColor(hex('#fff')),

						child('ui-character',
							flexGrow(1),

							style(':not(:last-child)',
								borderRight(px(1), 'solid', borderColor)
							)
						),
					)
						.attribute('ui-error',
							border(px(1), 'solid', errorColor),

							child('ui-character',
								style(':not(:last-child)',
									borderRight(px(1), 'solid', errorColor)
								)
							),
						)
						.attribute('ui-found',
							border(px(1), 'solid', successColor),

							child('ui-character',
								style(':not(:last-child)',
									borderRight(px(1), 'solid', successColor)
								)
							),
						),
				)
			),
		),

		child('ui-contest',
			display('flex'),
			flexDirection('column'),
			
			paddingBottom(rem(1)),

			child('ui-image',
				marginBottom(rem(1)),
				fontSize(0),

				child('img',
					horizontalImageStyle(),
					objectFit('cover'),
				)
			),

			child('ui-title',
				titleStyle(),
			),

			child('ui-text',
				marginBottom(contentSpacing),

				style(':first-of-type',
					marginTop(contentSpacing)
				),
			),

			child('ui-forms',
				display('flex'),
				flexDirection('column'),

				width(percentage(100)),

				child('ui-error-message',
					marginBottom(rem(0.75)),

					lineHeight(1),
					color(primaryColor),
				),

				child('input',
					inputStyle(),
				)
			)
		),

		child('ui-buttons-container',
			display('flex'),
			marginInline(rem(0.5).multiply(-1)),

			child('ui-button',
				display('flex'),
				justifyContent('center'),
				alignItems('center'),
				
				width(percentage(100).subtract(rem(0.5).multiply(2))),
				marginInline(rem(0.5)),

				buttonStyle(),
			)
		),
	),

	feedBackStyle(),
	defaultScreenStyle(),
);
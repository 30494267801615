import { Component } from "@acryps/page";
import { Application } from "..";
import { GameComponent } from "../game";

export class StartPlayerComponent extends Component {
	declare parent: GameComponent;

	players = Array<string>(Application.maxPlayers);
	playerMaxCharacters = 15;
	
	render() {
		return <ui-player-selection>
			<ui-title>
				{'Who is playing?'
					.german('Wer spielt mit?')
					.french('Qui joue?')
					.italian('Chi gioca?')
					.spanish('¿Quién juega?')
					.arabic('من سيلعب؟')
					.korean('누가 플레이하나요?')
					.japanese('誰がプレーするのか？')
				}
			</ui-title>

			<ui-instruction>
				{'Enter your name. If not all participants have their own device, up to three people can use the same device.'
					.german('Gib deinen Namen ein. Wenn nicht alle Teilnehmer ein eigenes Gerät haben, können bis zu drei Personen dasselbe Gerät verwenden.')
					.french(`Saisissez votre nom. Si tous les participants n'ont pas leur propre appareil, jusqu'à trois personnes peuvent utiliser le même appareil.`)
					.italian('Inserire il proprio nome. Se non tutti i partecipanti dispongono di un proprio dispositivo, è possibile utilizzare lo stesso dispositivo per un massimo di tre persone.')
					.spanish('Introduzca su nombre. Si no todos los participantes tienen su propio dispositivo, hasta tres personas pueden utilizar el mismo dispositivo.')
					.arabic('أدخل اسمك. إذا لم يكن لدى جميع المشاركين أجهزتهم الخاصة، فيمكن لما يصل إلى ثلاثة أشخاص استخدام نفس الجهاز.')
					.korean('이름을 입력합니다. 모든 참가자가 각자의 디바이스를 가지고 있지 않은 경우 최대 3명까지 동일한 디바이스를 사용할 수 있습니다.')
					.japanese('名前を入力してください。参加者全員が自分のデバイスを持っていない場合は、最大3人まで同じデバイスを使用できます。')
				}
			</ui-instruction>

			<ui-players>
				<ui-crown-container>
					<ui-crown>
						<img src='/assets/images/secondary-crown.svg' />
					</ui-crown>

					<ui-content>
						<ui-title>
							{`Player 1`
								.german('Spieler:in 1')
								.french('Joueur 1')
								.italian('Giocatore 1')
								.spanish('Jugador 1')
								.arabic('اللاعب 1')
								.korean('플레이어 1')
								.japanese('選手1')
							}
						</ui-title>
						
						<input
							$ui-value={Component.accessor(() => this.players[0], $$value => this.players[0] = $$value)}
							ui-change={() => this.update()}
							placeholder={
								`First name`
									.german(`Vorname`)
									.french(`Prénom`)
									.italian(`Nome`)
									.spanish('Nombre')
									.arabic('الاسم الأول')
									.korean('이름')
									.japanese('名前')
							}
							maxLength={this.playerMaxCharacters}
						/>
					</ui-content>
				</ui-crown-container>

				<ui-crown-container>
					<ui-crown>
						<img src='/assets/images/secondary-crown.svg' />
					</ui-crown>

					<ui-content>
						<ui-title>
							{`Player 2`
								.german('Spieler:in 2')
								.french('Joueur 2')
								.italian('Giocatore 2')
								.spanish('Jugador 2')
								.arabic('اللاعب 2')
								.korean('플레이어 2')
								.japanese('選手2')
							}
						</ui-title>

						<input
							$ui-value={Component.accessor(() => this.players[1], $$value => this.players[1] = $$value)}
							ui-change={() => this.update()}
							placeholder={
								`First name`
									.german(`Vorname`)
									.french(`Prénom`)
									.italian(`Nome`)
									.spanish('Nombre')
									.arabic('الاسم الأول')
									.korean('이름')
									.japanese('名前')
							}
							maxLength={this.playerMaxCharacters}
						/>
					</ui-content>
				</ui-crown-container>

				<ui-crown-container>
					<ui-crown>
						<img src='/assets/images/secondary-crown.svg' />
					</ui-crown>

					<ui-content>
						<ui-title>
							{`Player 3`
								.german('Spieler:in 3')
								.french('Joueur 3')
								.italian('Giocatore 3')
								.spanish('Jugador 3')
								.arabic('اللاعب 3')
								.korean('플레이어 3')
								.japanese('選手3')
							}
						</ui-title>

					<input
						$ui-value={Component.accessor(() => this.players[2], $$value => this.players[2] = $$value)}
						ui-change={() => this.update()}
						placeholder={
							`First name`
								.german(`Vorname`)
								.french(`Prénom`)
								.italian(`Nome`)
								.spanish('Nombre')
								.arabic('الاسم الأول')
								.korean('이름')
								.japanese('名前')
						}
						maxLength={this.playerMaxCharacters}
					/>
					</ui-content>
				</ui-crown-container>
			</ui-players>

			<ui-button
				ui-disabled={!this.players.map(player => player.trim()).some(player => player)}
				ui-click={() => {
					const names = [];

					for (let player of this.players) {
						const name = player?.trim();

						if (name) {
							const parts = name.split(/\s+/);

							for (let partIndex = 0; partIndex < parts.length; partIndex++) {
								parts[partIndex] = parts[partIndex][0].toUpperCase() + parts[partIndex].substring(1).toLowerCase();
							}

							names.push(parts.join(' '))
						}
					}

					this.parent.createSession(names);
				}}
			>
				{`Go!`
					.german(`Los!`)
					.french(`Allez!`)
					.italian(`Vai!`)
					.spanish('Vaya a')
					.arabic('اذهب')
					.korean('이동')
					.japanese('行く')
				}
			</ui-button>
		</ui-player-selection>;
	}
}